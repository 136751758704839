<template>
  <div class="schedule-list-view">
    <app-header></app-header>

    <section>
      <div class="card" width="100%">
        <div class="card-content pb-0">
          <b-col
            class="d-flex justify-content-start align-items-center"
            style="cursor: pointer"
          >
            <h3>Filtros</h3>
          </b-col>

          <b-col class="col-12 d-flex justify-content-between centered mb-3">
            <div class="col-12 d-flex flex-wrap">
              <div class="col-12 col-lg-3 col-md-5 mt-4">
                <app-input
                  label="Pesquisar"
                  v-model="searchPatientTerm"
                  placeholder="Pesquisar nome ou CPF"
                  icon="search"
                ></app-input>
              </div>

              <div class="col-12 col-lg-3 col-md-4 mt-4">
                <b-field label="Resultados p/ página">
                  <b-select class="select-custom" v-model="perPage" :value="10">
                    <option :key="option" v-for="option in pageOptions">
                      {{ option }}
                    </option>
                  </b-select>
                </b-field>
              </div>

              <div
                class="row col-12 col-lg-6 p-0 m-0 d-flex justify-content-end"
              >
                <b-col class="col-12 col-lg-auto">
                  <b-check
                    v-model="isCollapse"
                    class="d-flex align-items-center mt-6 ml-2"
                  >
                    Outros filtros
                  </b-check>
                </b-col>

                <b-col class="col-12 col-lg-auto filter-actions">
                  <b-button
                    id="filtrar"
                    class="button-rounded button-primary px-4 mt-lg-5 mr-md-2"
                    style="margin-top: 45px"
                    size="is-small"
                    icon-right="search"
                    @click="filtrar()"
                    :disabled="isLoading"
                  >
                    <b-icon icon="search"></b-icon>
                    Filtrar
                  </b-button>
                  <b-tooltip
                    target="filtrar"
                    title="Aplicar filtro para a pesquisa"
                    class="ml-2"
                  >
                  </b-tooltip>

                  <b-button
                    id="add_schedule"
                    class="button-rounded button-primary px-4 mt-lg-5"
                    style="margin-top: 45px"
                    @click="
                      () =>
                        $router.push({
                          name: 'schedules.create',
                          params: { id: this.doctorId },
                        })
                    "
                  >
                    <b-icon icon="plus"></b-icon>
                    Adicionar
                  </b-button>
                  <b-tooltip
                    target="add_schedule"
                    title="Adicionar novo atendimento"
                    class="ml-2"
                  ></b-tooltip>
                </b-col>

                <b-col class="col-12 col-lg-auto filter-custom">
                  <b-button
                    id="reset_filter"
                    class="px-2 mr-1"
                    @click="limpar_filtros()"
                    style="margin-top: 45px"
                  >
                    <b-icon icon="x-circle-fill"></b-icon>
                  </b-button>
                  <b-tooltip
                    target="reset_filter"
                    title="Limpar Filtros"
                    class="ml-2"
                  >
                  </b-tooltip>

                  <b-button
                    id="export_schedules"
                    class="px-2 mr-1"
                    :disabled="isEmpty || isLoading"
                    @click="exportSchedules()"
                    style="margin-top: 45px"
                  >
                    <b-icon icon="file-earmark-spreadsheet-fill"></b-icon>
                  </b-button>
                  <b-tooltip
                    target="export_schedules"
                    title="Exportar planilha de atendimentos"
                  >
                  </b-tooltip>
                </b-col>
              </div>
            </div>
          </b-col>

          <b-collapse id="collapseFilters">
            <div class="row centered container-filters">
              <div class="col-12 mt-4 d-flex flex-wrap filters">
                <div class="col-11 col-lg-3 col-md-8 mx-sm-auto mx-md-0">
                  <b-field label="Status pagamento">
                    <b-select
                      v-model="paymentStatusFilter"
                      class="input-custom"
                      expanded
                    >
                      <option
                        v-for="cPaymentStatus in paymentStatus"
                        :key="cPaymentStatus.id"
                        :value="cPaymentStatus.value"
                      >
                        {{ cPaymentStatus.name }}
                      </option>
                    </b-select>
                  </b-field>
                </div>

                <div class="col-11 col-lg-3 col-md-8 mx-sm-auto mx-md-0">
                  <b-field label="Status atendimento">
                    <b-select
                      v-model="status"
                      placeholder="Selecionar..."
                      class="input-custom"
                      expanded
                    >
                      <option value="ALL" :key="0">Todos</option>
                      <option value="CANCELED" :key="1">Canceladas</option>
                      <option value="PERFORMED" :key="2">Realizadas</option>
                      <option value="TO_PERFORM" :key="3">A realizar</option>
                      <option value="IN_PROGRESS" :key="4">Em andamento</option>
                      <option value="RESCHEDULED" :key="5">Reagendadas</option>
                    </b-select>
                  </b-field>
                </div>

                <div class="col-11 col-lg-3 col-md-8 mx-sm-auto mx-md-0">
                  <div class="label">Online/Agendado</div>

                  <b-field>
                    <b-select
                      v-model="tatendimento"
                      placeholder="Selecionar..."
                      class="select-custom"
                      expanded
                    >
                      <option :value="0">Todos</option>
                      <option :value="1">Atendimento Online</option>
                      <option :value="2">Atendimento Agendado</option>
                    </b-select>
                  </b-field>
                </div>
              </div>

              <div class="col-12 mt-4 d-flex flex-wrap filters">
                <div class="col-11 col-lg-3 col-md-8 mx-sm-auto mx-md-0">
                  <div class="label">Tipo de atendimento</div>

                  <b-field>
                    <multiselect
                      v-model="tipos_atendimento_selected"
                      :options="tipos_atendimento"
                      :multiple="true"
                      :close-on-select="false"
                      :clear-on-select="false"
                      placeholder="Selecionar..."
                      display-label="true"
                      label="id"
                      track-by="id"
                      :searchable="false"
                      :allow-empty="true"
                      :preselect-first="false"
                      open-direction="bottom"
                    >
                      <template slot="option" slot-scope="{ option }">
                        <span class="multiselect-option" :id="option.id">
                          {{ option.name }}
                        </span>
                      </template>

                      <template slot="selection" slot-scope="{ values }">
                        <p
                          class="multiselect-option m-0"
                          v-if="values.length > 0"
                        >
                          {{ values.length }}
                          {{
                            values.length > 1
                              ? 'tipos selecionados'
                              : 'tipo selecionado'
                          }}
                        </p>
                      </template>
                    </multiselect>
                    <!-- class="select" -->
                  </b-field>
                </div>

                <div class="col-11 col-lg-3 col-md-8 mx-sm-auto mx-md-0">
                  <div class="label">Tipo de agendamento</div>
                  <b-field>
                    <b-select
                      v-model="tipo_agendamento"
                      placeholder="Selecionar..."
                      class="select-custom"
                      expanded
                    >
                      <option :value="-1">Todos</option>
                      <option :value="0">Externo</option>
                      <option :value="1">Interno</option>
                    </b-select>
                  </b-field>
                </div>
              </div>

              <div class="col-12 d-flex flex-wrap mt-4 options-filters">
                <div class="col-11 col-lg-3 col-md-8 mx-sm-auto mx-md-0">
                  <div class="label">Tipo de pagamento</div>
                  <b-field>
                    <multiselect
                      v-model="selectedPaymentTypes"
                      :options="paymentTypes"
                      :multiple="true"
                      :close-on-select="false"
                      :clear-on-select="false"
                      placeholder="Selecionar..."
                      display-label="true"
                      label="id"
                      track-by="id"
                      :searchable="false"
                      :allow-empty="true"
                      :preselect-first="false"
                      open-direction="bottom"
                    >
                      <template slot="option" slot-scope="{ option }">
                        <span class="multiselect-option" :id="option.id">
                          {{ option.name }}
                        </span>
                      </template>

                      <template slot="selection" slot-scope="{ values }">
                        <p
                          class="multiselect-option m-0"
                          v-if="values.length > 0"
                        >
                          {{ values.length }}
                          {{
                            values.length > 1
                              ? 'tipos selecionados'
                              : 'tipo selecionado'
                          }}
                        </p>
                      </template>
                    </multiselect>
                  </b-field>
                </div>

                <div class="col-11 col-lg-3 col-md-8 mx-sm-auto mx-md-0">
                  <div class="label">Especialidade</div>

                  <b-field>
                    <multiselect
                      v-model="selectedSpecialties"
                      :options="specialties"
                      :multiple="true"
                      :close-on-select="false"
                      :clear-on-select="false"
                      placeholder="Selecionar..."
                      display-label="true"
                      label="id"
                      track-by="id"
                      :searchable="false"
                      :allow-empty="true"
                      :preselect-first="false"
                      open-direction="bottom"
                    >
                      <template slot="option" slot-scope="{ option }">
                        <span class="multiselect-option" :id="option.id">
                          {{ option.name }}
                        </span>
                      </template>

                      <template slot="selection" slot-scope="{ values }">
                        <p
                          class="multiselect-option m-0"
                          v-if="values.length > 0"
                        >
                          {{ values.length }}
                          {{
                            values.length > 1
                              ? 'especialidades selecionadas'
                              : 'especialidade selecionada'
                          }}
                        </p>
                      </template>
                    </multiselect>
                  </b-field>
                </div>

                <div class="col-11 col-lg-3 col-md-8 mx-sm-auto mx-md-0">
                  <div class="label">Período</div>

                  <b-field class="period-filter">
                    <b-select
                      v-model="periodFilter"
                      class="select-custom"
                      expanded
                    >
                      <option
                        v-for="period in periods"
                        :key="period.id"
                        :value="period.id"
                        :disabled="period.disabled"
                      >
                        {{ period.name }}
                      </option>
                    </b-select>
                  </b-field>
                </div>
              </div>

              <div class="col-12 d-flex flex-wrap mt-4 options-filters">
                <div
                  v-if="showCustomPeriod"
                  class="col-11 col-lg-2 mx-sm-auto mx-md-0"
                >
                  <div class="label">Período inicial</div>

                  <b-field>
                    <by-datepicker
                      icon="calendar"
                      placeholder="Escolha uma data"
                      v-model="periodStart"
                      @input="onRangeChange"
                      locale="pt-BR"
                    >
                    </by-datepicker>
                  </b-field>
                </div>

                <div
                  v-if="showCustomPeriod"
                  class="col-11 col-lg-2 mx-sm-auto mx-md-0"
                >
                  <div class="label">Período final</div>

                  <b-field>
                    <by-datepicker
                      icon="calendar"
                      placeholder="Escolha uma data"
                      v-model="periodEnd"
                      @input="onRangeChange"
                      locale="pt-BR"
                    >
                    </by-datepicker>
                  </b-field>
                </div>
              </div>
            </div>
          </b-collapse>
          <hr />
        </div>

        <div class="card-content p-2">
          <b-table
            id="table-schedules"
            thead-class="thead-custom"
            :fields="fields"
            :items="items"
            :per-page="perPage"
            :total="total"
            :busy="isLoading"
            sticky-header="450px"
            no-provider-sorting
            no-provider-filtering
            small
            responsive
            show-empty
            hover
          >
            <template #cell(name)="data">
              {{ data.value }}
            </template>

            <template #cell(cpf)="data">
              {{ data.value }}
            </template>

            <template #cell(date)="data">
              {{ data.value | date('DD/MM/YYYY [às] HH:mm') }}
            </template>

            <template #cell(phone)="data">
              {{ data.value > 0 ? toPhone(data.value) : data.value }}
            </template>

            <template #cell(appointment_type)="data">
              <div class="d-flex justify-content-center">
                <b-badge class="badge-custom appointment_type py-1">
                  {{ data.value || '-' }}
                </b-badge>
              </div>
            </template>

            <template #cell(tatendimento)="data">
              <div class="d-flex justify-content-center">
                <b-badge
                  class="badge-custom"
                  :class="
                    data.value.toLowerCase().includes('online')
                      ? 'appointment_online'
                      : 'appointment_schedule'
                  "
                >
                  {{ data.value.replace('Atendimento', '') }}
                </b-badge>
              </div>
            </template>

            <template #cell(time)="data">
              <div class="text-center">
                {{ `${data.value} min` }}
              </div>
            </template>

            <template #cell(specialty)="data">
              {{ data.value || '-' }}
            </template>

            <template #cell(payment_type)="data">
              {{ data.value || '-' }}
            </template>

            <template #cell(value)="data">
              <span v-if="data.value > 0">
                {{ data.value | currency('BRL') }}
              </span>
              <span v-else>
                {{ '-' }}
              </span>
            </template>

            <template #cell(value_fee)="data">
              <span v-if="data.value > 0">
                {{ data.value | currency('BRL') }}
              </span>
              <span v-else>
                {{ '-' }}
              </span>
            </template>

            <template #cell(schedule_status)="data">
              <b-badge
                class="badge-custom p-1"
                :variant="`${data.item.schedule_status.type}`"
              >
                {{ data.item.schedule_status.label }}
              </b-badge>
            </template>

            <template #cell(payment_status)="data">
              <span v-if="data.value > 0">
                <b-badge
                  class="badge-custom"
                  :variant="`${data.item.payment_status.type}`"
                >
                  {{ data.item.payment_status.label }}
                </b-badge>
              </span>
              <span v-else>
                {{ '-' }}
              </span>
            </template>

            <template #cell(options)="data">
              <b-col class="d-flex">
                <b-button
                  class="button-primary remove-focus border-0 ml-2 h5"
                  style="border-radius: 7px"
                  v-b-tooltip.hover.left="'Acessar atendimento'"
                  @click="
                    () =>
                      $router.push({
                        name: 'schedules.view',
                        params: { id: data.item.id },
                      })
                  "
                >
                  <b-icon icon="arrow-up-right" class="text-white"></b-icon>
                </b-button>

                <b-button
                  class="bg-info remove-focus border-0 ml-2 h5"
                  v-if="data.item.checkout_url && !!data.item.internal"
                  v-b-tooltip.hover.left="'Copiar link de pagamento'"
                  size="is-small"
                  @click="copyCheckouUrl(data.item.checkout_url)"
                >
                  <b-icon icon="card-checklist" class="text-white"></b-icon>
                </b-button>
              </b-col>
            </template>

            <!-- SLOT DOS CABEÇALHO -->
            <template #head()="fth">
              <div class="column-custom" :class="fth.column">
                {{ fth.label }}
              </div>
            </template>

            <!-- SLOT DE CARREGAMENTO -->
            <template #table-busy>
              <section class="section">
                <div class="text-center my-2">
                  <b-spinner class="align-middle mr-2" small></b-spinner>
                  <strong style="margin-left: 2px">Carregando...</strong>
                </div>
              </section>
            </template>

            <!-- SLOT CONTEÚDO VAZIO -->
            <template slot="empty">
              <section class="section">
                <div class="d-flex justify-content-center align-items-ter">
                  <p class="mt-4">Nenhum registro encontrado.</p>
                </div>
              </section>
            </template>
          </b-table>
        </div>

        <!-- PAGINATION -->
        <div class="card-content pt-2">
          <b-col class="d-flex justify-content-end">
            <b-col
              class="col-sm-6 col-md-auto d-flex align-items-center mr-sm-0 mr-md-2"
            >
              <p class="p-0 m-0">
                Total de registro: <strong>{{ schedules.length }}</strong>
              </p>
            </b-col>

            <b-col class="col-sm-6 col-md-auto">
              <b-pagination
                class="pagination-custom"
                page-class="pagination-custom-page"
                next-class="pagination-custom-next"
                prev-class="pagination-custom-prev"
                v-model="page"
                :per-page="perPage"
                :total-rows="total"
                :disabled="isLoading"
                aria-controls="table-schedules"
                first-number
                last-number
              ></b-pagination>
            </b-col>
          </b-col>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import * as moment from 'moment';
import BuefyMixin from '@/mixins/BuefyMixin';
import Multiselect from 'vue-multiselect';
import debounce from 'lodash/debounce';

import MedicalRecordsList from '@/modals/MedicalRecordsList.vue';
import PrescriptionsList from '@/modals/PrescriptionsList.vue';
import SchedulesService from '@/services/schedules.service';
import SpecialtiesService from '@/services/specialties.service';
import ProceduresService from '@/services/procedures.service';

import PERIODS from '@/constants/periods.constants';
import PAYMENT_STATUS from '@/constants/payment_status.constants';

export default {
  components: { Multiselect },
  mixins: [BuefyMixin],

  data: () => ({
    isLoading: true,
    isCollapse: false,
    isInitAnimation: false,
    doctorId: '',
    searchDoctorTerm: '',
    searchPatientTerm: '',
    paymentStatus: PAYMENT_STATUS,
    showCustomPeriod: false,
    periodFilter: 2,
    periods: PERIODS,
    selectedPeriod: 10,
    paymentStatusFilter: 'ALL',
    searchTerm: '',
    type: 0,
    status: 'ALL',
    dates: [],
    periodStart: null,
    periodEnd: null,
    customDates: null,
    schedules: [],
    total: 0,
    page: 1,
    perPage: 10,
    period: [],
    total_value: 0,
    search_value: 0,
    totalValue: 0,
    total_carried_out: 0,
    total_schedules: 0,
    schedule_time: 0,
    procedures: [],
    searchProcedure: null,
    balance_info: {
      balance: null,
      withheld: null,
      transferable: null,
      gateway_fee: null,
    },
    schedule_values: {},
    tipos_atendimento_selected: [],
    tipos_atendimento: [
      { id: 1, name: '1 - Chat' },
      { id: 2, name: '2 - Ligação' },
      { id: 3, name: '3 - Vídeo Chamada' },
    ],
    paymentTypes: [
      { id: 1, name: 'Pix' },
      { id: 2, name: 'Cartão 1x' },
      { id: 3, name: 'Cartão 2x' },
      { id: 4, name: 'Cartão 3x' },
    ],
    selectedPaymentTypes: [],
    specialties: [],
    selectedSpecialties: [],
    tipo_agendamento: -1,
    tatendimento: 0,
    pageOptions: [10, 20, 40],
  }),

  mounted() {
    this.onPeriodFilterChange();
    // this.loadProcedures();
    this.loadSpecialties();
    this.loadAppointments();

    this.isInitAnimation = true;
  },

  watch: {
    isCollapse() {
      this.isInitAnimation = false;
      this.$root.$emit('bv::toggle::collapse', 'collapseFilters');
    },

    periodFilter() {
      this.onPeriodFilterChange();
    },

    searchPatientTerm: debounce(function () {
      this.loadAppointments();
    }, 300),

    perPage() {
      this.loadAppointments();
    },

    page() {
      this.loadAppointments();
    },
  },

  computed: {
    fields: () => [
      {
        key: 'name',
        label: 'CLIENTE',
        sortable: true,
      },
      {
        key: 'cpf',
        label: 'CPF',
        sortable: true,
      },
      {
        key: 'date',
        label: 'DATA/HORÁRIO',
        sortable: true,
      },
      {
        key: 'phone',
        label: 'TELEFONE',
        sortable: true,
      },
      {
        key: 'appointment_type',
        label: 'T. ATEND.',
        sortable: true,
      },
      {
        key: 'tatendimento',
        label: 'ATEND.',
        sortable: true,
      },
      {
        key: 'time',
        label: 'Tempo de Aten.',
        sortable: true,
      },
      {
        key: 'specialty',
        label: 'ESPECIALIDADE',
        sortable: true,
      },
      {
        key: 'payment_type',
        label: 'T. PGTO.',
        sorteable: true,
      },
      {
        key: 'value',
        label: 'VALORES',
        sortable: true,
      },
      {
        key: 'value_fee',
        label: 'TAXAS',
        sortable: true,
      },
      {
        key: 'schedule_status',
        label: 'STATUS',
        sortable: true,
      },
      {
        key: 'payment_status',
        label: 'STATUS PGTO.',
        sortable: true,
      },
      {
        key: 'options',
        label: 'OPÇÕES',
      },
    ],

    items() {
      return (
        this.schedules?.map((item) => ({
          ...item,
          name: item?.patient?.name,
          cpf: this.formatDocument(item?.patient?.cpf ?? ''),
          date: item?.start,
          phone: item.patient.phone,
          appointment_type: item?.appointment_types?.name,
          tatendimento: item?.tatendimento?.name,
          specialty: item?.specialty?.name,
          payment_type: item?.forma_pagamento.name,
          value: item?.value,
          value_fee: item?.value_fee,
          procedure: item?.procedure?.name ?? '-',
        })) ?? []
      );
    },

    isEmpty() {
      return this.schedules.length === 0;
    },
  },

  methods: {
    loadSpecialties() {
      SpecialtiesService.getSpecialties({
        'filter[specialty_schedule]': true,
      }).then((data) => {
        this.specialties = data.data;
      });
    },

    loadAppointments() {
      this.isLoading = true;

      const filters = this.collectFilters();

      SchedulesService.get(filters)
        .then(({ data }) => {
          const { total = 0, data: items = [], current_page = 10 } = data;

          if (this.page <= 1) {
            this.page = current_page;
            this.total = total;
          }

          this.schedules = items ?? [];

          if (this.schedules.length > 0) {
            this.doctorId = this.schedules[0]['doctor_id'];
          }
        })
        .finally(() => (this.isLoading = false));
    },

    loadProcedures() {
      this.isLoading = true;
      ProceduresService.get({})
        .then(({ data }) => {
          this.procedures = data;
        })
        .finally(() => setTimeout(() => (this.isLoading = false), 300));
    },

    filtrar() {
      this.page = 1;
      this.loadAppointments();
    },

    collectFilters() {
      const params = {
        page: this.page ?? 1,
        perPage: this.perPage,
      };

      if (this.dates.length == 2) {
        params[
          'filter[start_end_between]'
        ] = `${this.dates[0]},${this.dates[1]}`;
      }

      if (this.searchPatientTerm) {
        params['filter[patient_info]'] = this.searchPatientTerm
          .toLowerCase()
          .trim();
      }

      /*  if (this.type >= 0) {
        params['filter[appointment_type]'] = this.type;
      } */

      if (this.searchProcedure) {
        params['filter[procedure_id]'] = this.searchProcedure;
      }

      if (this.status) {
        params['filter[status]'] = this.status;
      }

      if (this.paymentTypes.length > 0) {
        params['filter[forma_pagamento]'] = this.selectedPaymentTypes
          .map((item) => item.id)
          .join(',');
      }

      if (this.selectedSpecialties.length > 0) {
        params['filter[specialty]'] = this.selectedSpecialties
          .map((item) => item.id)
          .join(',');
      }

      if (this.tipos_atendimento_selected.length > 0) {
        params['filter[appointment_types]'] = this.tipos_atendimento_selected
          .map((item) => item.id)
          .join(',');
      }

      if (this.paymentStatusFilter) {
        params['filter[payment_status]'] = this.paymentStatusFilter;
      }

      if (this.tatendimento > 0) {
        params['filter[tatendimento]'] = this.tatendimento;
      }

      if (![-1, '', null].includes(this.tipo_agendamento)) {
        params['filter[type]'] = this.tipo_agendamento;
      }

      return params;
    },

    onRangeChange() {
      if (this.periodStart && moment(this.periodStart).isValid()) {
        this.periodStart = moment(this.periodStart).format('YYYY-MM-DD');
      }

      if (this.periodEnd && moment(this.periodEnd).isValid()) {
        this.periodEnd = moment(this.periodEnd).format('YYYY-MM-DD');
      }

      this.dates = [this.periodStart, this.periodEnd];
    },

    onPeriodFilterChange() {
      const period = this.periods.find((item) => item.id == this.periodFilter);
      this.page = 1;
      this.showCustomPeriod = false;

      this.dates = [];

      if (period.custom) {
        this.showCustomPeriod = true;
      } else {
        const { start, end } = period;

        if (start && moment(start).isValid()) {
          this.dates[0] = start.format('YYYY-MM-DD');
        }

        if (end && moment(end).isValid()) {
          this.dates[1] = end.format('YYYY-MM-DD');
        }
      }
    },

    limpar_filtros() {
      this.searchDoctorTerm = '';
      this.searchPatientTerm = '';
      this.searchApproved = -1;
      this.searchActive = -1;
      this.searchJuno = -1;
      this.searchDate = null;
      this.searchDateEnd = null;
      this.online = false;
      this.sorter = false;
      this.status = 'ALL';
      this.forma_pagamento = 'ALL';
      this.tatendimento = 0;
      this.selectedPaymentTypes = [];
      this.selectedSpecialties = [];
      this.paymentStatusFilter = 'ALL';
      this.tipos_atendimento_selected = [];
      this.tipo_agendamento = -1;
      this.searchSpecialtyId = null;
      this.datesFilter = [];
      this.periodFilter = 10;
      this.periodStart = '';
      this.periodEnd = '';
      this.selectedPeriod = '';

      this.dates = [
        moment().startOf('month').format('YYYY-MM-DD'),
        moment().endOf('month').format('YYYY-MM-DD'),
      ];

      this.page = 1;
      this.filtrar();
    },

    toPhone(value) {
      if (value.length == 10) {
        return value.replace(/^(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
      }
      if (value.length == 11) {
        return value.replace(/^(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
      }
    },

    formatDocument(value) {
      return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    },

    getMoneyValue(value) {
      if (!value) return (0).toFixed(2);

      return Number(value).toFixed(2);
    },

    openPrescriptions(scheduleId) {
      this.$buefy.modal.open({
        parent: this,
        component: PrescriptionsList,
        hasModalCard: true,
        trapFocus: true,
        canCancel: ['escape', 'x', 'outside'],
        props: {
          scheduleId,
        },
      });
    },

    openRecords(scheduleId) {
      this.$buefy.modal.open({
        parent: this,
        component: MedicalRecordsList,
        hasModalCard: true,
        trapFocus: true,
        canCancel: ['escape', 'x', 'outside'],
        props: {
          scheduleId,
        },
      });
    },

    exportSchedules() {
      const filters = this.collectFilters();

      SchedulesService.export(filters)
        .then(({ data }) => {
          const fileName = data.split('/')[data.split('/').length - 1];
          const fileLink = document.createElement('a');

          fileLink.target = '_blank';
          fileLink.href = data;
          fileLink.setAttribute('download', fileName);
          fileLink.setAttribute('style', 'display:none;');

          document.body.appendChild(fileLink);
          fileLink.click();

          fileLink.remove();
        })
        .catch(({ response }) => {
          const message =
            response.data.message || 'Erro ao realizar exportação.';
          this.$buefy.snackbar.open(message);
        });
    },

    copyCheckouUrl(url) {
      const input = document.createElement('input');
      document.body.appendChild(input);
      input.value = url;

      input.focus();
      input.select();

      if (document.execCommand('copy')) {
        this.$buefy.snackbar.open({
          message: 'Link copiado com sucesso!',
          type: 'is-success',
        });
      }

      document.body.removeChild(input);
    },

    isAfter30Minutes(schedule) {
      const current_date = moment();

      return moment(schedule.start).diff(current_date, 'minutes') >= 30;
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
.arrow-icon {
  color: #353535;
  width: 25px;
  height: 25px;
  transform: rotate(180deg);
  transition: transform 0.3s ease-in-out;

  &.arrow-top {
    transform: rotate(180deg);
  }

  &.arrow-bottom {
    transform: rotate(0deg);
  }
}

.container-filters {
  min-width: 100%;
}

.filter-actions,
.filter-custom {
  & svg.b-icon {
    font-size: 18px;
    vertical-align: middle;
    margin-bottom: 2px;
  }
}

.filter-actions {
  & svg.b-icon {
    margin-right: 5px;
  }
  & svg.b-icon.bi-search {
    font-size: 16px;
  }

  & svg.b-icon.bi-plus {
    font-size: 22px;
  }
}

.filter-custom {
  & button {
    background-color: transparent;
    border-color: var(--color-secondary);

    & svg.b-icon {
      color: var(--color-secondary);
    }
  }
}

.box-checkbox-filter-others {
  display: flex;
  align-items: center;
  justify-content: center;
}

.checkbox-filter-others {
  padding: 0;
  font-size: 0.8rem;
  font-weight: 600;
}

.badge-custom {
  &.appointment_online {
    background-color: #2dd36f;
  }

  &.appointment_schedule {
    background-color: #ff9100;
  }

  &.appointment_type {
    background-color: #5260ff;
  }
}

.column-custom {
  width: 195px;
  padding: 0 15px;

  &.payment_type {
    width: 160px;
  }

  &.tatendimento,
  &.value,
  &.value_fee {
    width: 120px;
  }

  &.schedule_status {
    width: 140px;
  }

  &.time,
  &.cpf,
  &.payment_status {
    width: 150px;
  }

  &.options {
    width: auto;
  }
}


</style>
