const PAYMENT_STATUS = [
  {
    id: 0,
    value: 'ALL',
    name: 'Todos',
  },
  {
    id: 1,
    value: 'CANCELED',
    name: 'Cancelado',
  },
  {
    id: 2,
    value: 'WAITING',
    name: 'Aguardando',
  },
  {
    id: 3,
    value: 'CONFIRMED',
    name: 'Confirmado',
  },
  {
    id: 4,
    value: 'REFUND',
    name: 'Estornado',
  },
  {
    id: 5,
    value: 'PARTIAL_REFUND',
    name: 'Estorno parcial',
  },
];

export default PAYMENT_STATUS;
